import { API } from "@/services"

export const USERS_ENDPOINTS = {
  getMyUser(params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `users/me`,
      method: 'GET',
      params: params,
    })
  },
  updateMyUser(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `users/me`,
      method: 'PUT',
      data: data,
    })
  },
  updateTwoFA(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `users/me/two-fa`,
      method: 'PATCH',
      data: data,
    })
  },
  updatePreferences(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `users/me/preferences`,
      method: 'PUT',
      data: data,
    })
  },
  updateMemory(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `users/me/memory`,
      method: 'PUT',
      data: data,
    })
  },
}