export const languages = {
  english: {
    text: 'English',
    value: 'english',
  },
  hindi: {
    text: 'Hindi',
    value: 'hindi',
  },
  urdu: {
    text: 'Urdu',
    value: 'urdu',
  },
}