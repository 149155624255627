import { API } from "@/services"

export const TEMPLATES_ENDPOINTS = {
  create(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates`,
      method: 'POST',
      data,
    })
  },
  update(template_id,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}`,
      method: 'PUT',
      data,
    })
  },
  updateVisibility(template_id,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}/visibility`,
      method: 'PATCH',
      data,
    })
  },
  list(params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates`,
      method: 'GET',
      params,
    })
  },
  community(params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/community`,
      method: 'GET',
      params,
    })
  },
  delete(template_id,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}`,
      method: 'DELETE',
      data,
    })
  },
  getById(template_id,params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}`,
      method: 'GET',
      params,
    })
  },
  dublicate(template_id,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}/dublicate`,
      method: 'POST',
      data,
    })
  },
  toggleFavourite(template_id,data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/${template_id}/favourite`,
      method: 'PATCH',
      data,
    })
  },
  favourites(params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `templates/favourites`,
      method: 'GET',
      params,
    })
  },
}