export const voices = {
  goldilocks: { 
    text: 'Goldilocks', 
    value: 'goldilocks' 
  }, 
  brief: { 
    text: 'Brief', 
    value: 'brief' 
  }, 
  detailed: { 
    text: 'Detailed', 
    value: 'detailed' 
  }, 
  super_detailed: { 
    text: 'Super detailed', 
    value: 'super_detailed' 
  }
}