import { io } from "socket.io-client";
import Emitter from 'component-emitter';
import { GET_TOKEN, REFRESH_TOKEN } from "@/store/constants";
import store from "@/store";
export const MEDICAL_HEALTH_SOCKET = {
  socket: null,
  connected: false,
  connecting: false,
  init(){
    Emitter(this)
    window.MEDICAL_HEALTH_SOCKET = this
    return this
  },
  connect(){
    const token = store.getters[GET_TOKEN]
    const url = new URL(process.env.VUE_APP_MEDICAL_HEALTH_API_URL)
    this.socket = io(`${url.origin}`,{
      autoConnect: false,
      closeOnBeforeunload: true,
      auth(cb){
        cb({
          token: token
        })
      }
    });
    this.socket.on('connect',this.onConnect.bind(this))
    this.socket.on('disconnect',this.onDisconnect.bind(this))
    this.socket.on("error", this.onError.bind(this));
    this.socket.onAny(this.onAnyEvent.bind(this));
    this.socket.connect()
  },
  disconnect(){
    this.socket?.disconnect?.()
  },
  onConnect(){
    this.emit('connect')
    this.connected=true
  },
  async onError(payload){
    console.log(payload)
    if(['token-expired','invalid-token'].includes(payload.flag)) {
      await store.dispatch(REFRESH_TOKEN)
      this.connect()
    }
  },
  onDisconnect(){
    this.emit('disconnect')
    this.connected=false
  },
  onAnyEvent(event,payload){
    this.emit(event,payload)
  },
}