// SECTION - AUTH
export const SET_TOKEN = 'SET_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
// SECTION - APP
export const SET_FETCH_START_UP_RECORD = 'SET_FETCH_START_UP_RECORD'
export const SET_USER = 'SET_USER'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE'
export const SET_FAVOURITE_TEMPLATES = 'SET_FAVOURITE_TEMPLATES'
export const SET_SESSIONS = 'SET_SESSIONS'
export const ADD_SESSION = 'ADD_SESSION'
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_USER_ABOUT = 'UPDATE_USER_ABOUT'
export const UPDATE_USER_TWO_FA = 'UPDATE_USER_TWO_FA'
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES'
export const UPDATE_USER_MEMORY = 'UPDATE_USER_MEMORY'
// SECTION - SETTING
export const SET_THEME = 'SET_THEME'
export const SET_SYSTEM_THEME = 'SET_SYSTEM_THEME'
export const SET_LANGUAGE = 'SET_LANGUAGE'
// SECTION - ELECTRON
export const SET_UPDATE_STATUS = 'SET_UPDATE_STATUS'
export const SET_UPDATE_AVAILABLE_VERSION = 'SET_UPDATE_AVAILABLE_VERSION'
export const SET_UPDATE_ERROR_MESSAGE = 'SET_UPDATE_ERROR_MESSAGE'
export const SET_UPDATE_DOWNLOAD_PROGRESS = 'SET_UPDATE_DOWNLOAD_PROGRESS'
export const SET_APP_VERSION = 'SET_APP_VERSION'