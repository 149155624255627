import { API } from "@/services"

export const FILES_ENDPOINTS = {
  getById(file_id,params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `files/${file_id}`,
      method: 'GET',
      params,
      responseType: 'blob',
      timeout: 10*24*60*60*1000
    })
  },
}