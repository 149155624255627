import { API } from "@/services"

export const TESTING_ENDPOINTS = {
  makeTemplate(data={}){
    return API.Axios.medical_health_api.v1.request({
      url: `testing/make-template`,
      method: 'POST',
      data: data,
      authentication: true,
    })
  },
}