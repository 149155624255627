<template>
  <div class="content">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Content',
}
</script>

<style lang="scss">
.content {
  grid-area: content;
  height: 100vh;
  overflow: hidden;
}
.scale {
  &-enter-active,&-leave-active {
    transition: all 0.5s ease;
  }
  &-enter-from,&-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
}


.md-content{
  padding: 32px 32px 8px 32px;
  gap: 16px;
  background-color: rgb(248 250 252);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-area: content;
  .md-content-topSection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .md-content-topSection-left{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .md-content-topSection-right{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .md-content-bottomSection{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 12px;
  }
  &.verticalScroll{
    overflow-y: auto;
    max-height: 100vh;
    .md-content-bottomSection{
      overflow: visible;
    }
  }
  &.max-width-1280px{
    align-items: center;
    .md-content-topSection{
      max-width: 1280px;
    }
    .md-content-bottomSection{
      max-width: 1280px;
    }
  }
}

.md-content-heading{
  line-height: 1.3;
  font-weight: 500;
  font-size: 30px;
  color: rgb(2, 8, 23);
  &.font-48px{
    font-size: 48px;
  }
}

.md-content-description{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: rgb(100, 116, 139);
}

.md-content-subHeading{
  line-height: 1.3;
  font-weight: 500;
  font-size: 16px;
  color: rgb(2, 8, 23);
  &.font-20px{
    font-size: 20px;
  }
}

.md-content-devider{
  width: 100%;
  min-height: 1px;
  background-color: rgb(226, 232, 240);
  margin: 40px 0px;
}

.MultiLineButtonType{
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  background-color: white;
  border: 1px solid rgb(226, 232, 240);
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 2.75rem;
  cursor: pointer;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  &:focus{
    box-shadow: none !important;
  }
  &:focus-visible{
    outline: -webkit-focus-ring-color auto 0px;
  }
  &:active{
    background-color: white;
    border-color: rgb(226, 232, 240);
  }
  .buttonIsMultiLine-smallText{
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    color: rgb(100, 116, 139);
    text-align: left;
    max-width: 200px;
  }
  .buttonIsMultiLine-bigText{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    color: rgb(2, 8, 23);
    text-align: left;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.md-content-textBoxSection{
  flex: 1 1 0%;
  position: relative;
  overflow-y: scroll;
  padding: 24px;
  border: 1px solid rgb(226, 232, 240);
  background-color: white;
  border-radius: 8px;
  .md-content-textBoxSection-itself{
    position: relative;
    height: auto;
    width: 100%;
  }
}

.md-content-AI-textBoxSection{
  position: relative;
  padding-left: .75rem;
  padding-right: .75rem;
  background-color: white;
  border: 1px solid rgb(226, 232, 240);
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  gap: 8px;
  input{
    border: none;
    height: 50px;
    width: calc(100% - 8px - 22px);
    background-color: transparent;
    font-size: 14px;
    &:focus-visible{
			outline: -webkit-focus-ring-color auto 0px;
		}
  }
}

.md-content-bottomInstructionText{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(249, 115, 22);
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
}

</style>