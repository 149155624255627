export const date_formats = { 
  'DD/MM/YYYY': {
    text: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  }, 
  'MM/DD/YYYY': {
    text: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  }, 
  'YYYY/MM/DD': {
    text: 'YYYY/MM/DD',
    value: 'YYYY/MM/DD',
  }, 
  'DD MMM YYYY': {
    text: 'DD MMM YYYY',
    value: 'DD MMM YYYY',
  },  
  'DD MMMM YYYY': {
    text: 'DD MMMM YYYY',
    value: 'DD MMMM YYYY',
  },  
  'MMM DD YYYY': {
    text: 'MMM DD YYYY',
    value: 'MMM DD YYYY',
  },  
  'MMMM DD YYYY': {
    text: 'MMMM DD YYYY',
    value: 'MMMM DD YYYY',
  },  
}