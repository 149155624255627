import { AUTH_ENDPOINTS } from "./auth/index"
import { FILES_ENDPOINTS } from "./files"
import { SESSIONS_ENDPOINTS } from "./sessions"
import { SPECIALITIES_ENDPOINTS } from "./specialities"
import { TEMPLATES_ENDPOINTS } from "./templates"
import { TESTING_ENDPOINTS } from "./testing"
import { USERS_ENDPOINTS } from "./users"

export const API_ENDPOINTS = {
  auth: AUTH_ENDPOINTS,
  users: USERS_ENDPOINTS,
  sessions: SESSIONS_ENDPOINTS,
  templates: TEMPLATES_ENDPOINTS,
  files: FILES_ENDPOINTS,
  testing: TESTING_ENDPOINTS,
  specialities: SPECIALITIES_ENDPOINTS
}