<template>
  <div class="h-100 w-100 auth-layout d-flex justify-content-center">
    <!-- <div class='box'>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
    <div class="area" >
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="w-50 d-flex flex-column justify-content-center align-items-center">
      <b-container>
        <b-card class="w-100 auth-card">
          <b-card-header class="d-flex justify-content-center mt-2">
            <!-- <b-img-lazy v-if="GET_IS_DARK_MODE" :src="require('@/assets/images/VOIPLogo-white.svg')" height="50" />
            <b-img-lazy v-else :src="require('@/assets/images/VOIP-Phone-Business-Logo.svg')" height="50" /> -->
          </b-card-header>
          <b-card-body>
            <router-view />
          </b-card-body>
        </b-card>
      </b-container>
    </div>
  </div>
</template>

<script>
import { GET_IS_DARK_MODE } from '@/store/constants'
import { mapGetters } from 'vuex'
export default {
  name: 'AuthLayout',
  computed: {
    ...mapGetters([
      GET_IS_DARK_MODE
    ])
  },
}
</script>

<style lang="scss" scoped>
// .box {
// 	position: fixed;
// 	top: 0;
// 	transform: rotate(80deg);
// 	left: 0;
//   > div {
//     position: fixed;
//     top: 0;
//     left: 0;
//     opacity: .4;
//     position: absolute;
//     top: 3%;
//     left: 10%;
//     background: $primary;
//     width: 1500px;
//     height: 1300px;
//     margin-left: -150px;
//     margin-top: -250px;
//     transform-origin: 50% 48%;
//     border-radius: 43%;
//     animation: drift 7000ms infinite linear;
//     &:nth-child(2) {
//       animation: drift 3000ms infinite linear;
//       opacity: .1;
//       background: black;
//       position: fixed;
//     }
//     &:nth-child(3) {
//       animation: drift 7500ms infinite linear;
//       position: fixed;
//       background-color: $primary;
//     }
//   }
//   &:after {
//     content: '';
//     display: block;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 11;
//     transform: translate3d(0, 0, 0);
//   }
// }
// @keyframes drift {
//   from { 
//     transform: rotate(0deg); 
//   }
//   from { 
//     transform: rotate(360deg); 
//   }
// }

.area {
  background: transparent;
  width: 100%;
  height: 100vh;
  position: absolute;
  > .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    > li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(0, 0, 0, 0.2);
      // background: rgba(85, 118, 209, 0.2);
      animation: animate 25s linear infinite;
      bottom: -150px;
      &:nth-child(1){
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }
      &:nth-child(2){
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }
      &:nth-child(3){
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }
      &:nth-child(4){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }
      &:nth-child(5){
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }
      &:nth-child(6){
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }
      &:nth-child(7){
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }
      &:nth-child(8){
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }
      &:nth-child(9){
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }
      &:nth-child(10){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
      }
    }
  }
}
@keyframes animate {
  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>