<template>
  <div class="position-absolute">
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { FETCH_START_UP_RECORD } from '@/store/constants'
import { MEDICAL_HEALTH_SOCKET } from '@/sockets'
export default {
  name: 'Common',
  methods: {
    ...mapActions([
      FETCH_START_UP_RECORD,
    ])
  },
  mounted(){
    this[FETCH_START_UP_RECORD]()
    MEDICAL_HEALTH_SOCKET.connect()
  },
  beforeDestroy(){
    // TODO - 
  },
}
</script>

<style>

</style>