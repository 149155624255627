export const brains = {
  left: {
    icon: 'left-brain',
    text: 'Left',
    short_text: 'L',
    value: 'left',
    description: 'Fast and unimaginative. Can lack detail. Good for simple sessions.',
  },
  right: {
    icon: 'right-brain',
    text: 'Right',
    short_text: 'R',
    value: 'right',
    description: 'Thoughtful, but slow. Can infer meaning. Good for complex sessions.',
  },
}