import store from '@/store'
import { GET_USER } from '@/store/constants'
import moment from 'moment'
export function date_format(value,options={}){ 
  let default_format = store.getters[GET_USER]?.defaultSetting?.date_format || `DD/MM/YYYY`
  let moment_instance = null, response  = ''
  if(options?.input?.unix) moment_instance = moment.unix(value)
  else moment_instance = moment.utc(value)
  if(options?.output?.format) response = moment_instance.format(options.output.format)
  else response = moment_instance.format(`${default_format} hh:mm a`)
  if(response=='Invalid date') response=''
  return response
}