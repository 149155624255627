import { API } from "@/services"

export const SPECIALITIES_ENDPOINTS = {
  list(params={}){
    return API.Axios.medical_health_api.v1.request({
      url: `specialities`,
      method: 'GET',
      params: params,
    })
  },
}