import router from "@/router";
import { API } from "@/services";
import { HELPER_FUNCTIONS } from "@/utils/index";
import { LOGOUT, REFRESH_TOKEN, RESET_START_UP_RECORD } from "../constants/actions";
import { GET_IS_LOGGED_IN, GET_TOKEN, GET_TOKEN_PAYLOAD, GET_REFRESH_TOKEN } from "../constants/getters";
import { SET_TOKEN, SET_REFRESH_TOKEN } from "../constants/mutations";
import debounce from 'lodash.debounce'
import { routes_names } from "@/router/constants";
import { MEDICAL_HEALTH_SOCKET } from "@/sockets/medical-health";
export default {
  state: {
    token: '',
    refresh_token: '',
  },
  getters: {
    [GET_IS_LOGGED_IN](state,getters){ return !!getters[GET_TOKEN]},
    [GET_TOKEN](state){ return state.token },
    [GET_REFRESH_TOKEN](state){ return state.refresh_token },
    [GET_TOKEN_PAYLOAD](state,getters){ return HELPER_FUNCTIONS.parseJwt(getters[GET_TOKEN]) ?? {} },
  },
  actions: {
    [REFRESH_TOKEN]: debounce(async function(context){
      try {
        const { data } = await API.endpoints.auth.refreshToken({
          refresh_token: context.getters[GET_REFRESH_TOKEN]
        })
        context.commit(SET_TOKEN,data.access_token)
        context.commit(SET_REFRESH_TOKEN,data.refresh_token)
      } catch (ex) {
        context.dispatch(LOGOUT)
      }
    },3*1000,{
      leading: true,
      trailing: false,
    }),
    async [LOGOUT](context){
      try {
        await API.endpoints.auth.logout()
      } finally {
        MEDICAL_HEALTH_SOCKET.disconnect()
        context.commit(SET_TOKEN,'')
        context.commit(SET_REFRESH_TOKEN,'')
        context.dispatch(RESET_START_UP_RECORD)
        const requiresAuth = router.currentRoute.matched.some((record) => record.meta.requiresAuth)
        if(requiresAuth) {
          router.push({
            name: routes_names.login
          })
        }
      }
    },
  },
  mutations: {
    [SET_TOKEN](state,value){
      state.token=value ?? ''
    },
    [SET_REFRESH_TOKEN](state,value){
      state.refresh_token=value ?? ''
    },
  },
};